import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {StyledContainer, StyledNotFoundWrapper} from '@pages/not-found/style.ts'
import {Helmet} from 'react-helmet'
import {routes} from '@/utilities/constants/routes'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'

export const NotFound = () => {
    const {t} = useTranslation()

    return (
        <StyledContainer fullHeight>
            <Helmet title={t('notFound:page_title_meta')} />
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>{t('notFound:title')}</h1>
                <p>{t('notFound:subtitle')}</p>
                <ButtonLink variant={'primary'} size={'lg'} to={routes.CHAT.path}>
                    {t('notFound:cta')}
                </ButtonLink>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
