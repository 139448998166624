import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css, keyframes} from 'styled-components'

export const StyledAvatarMessage = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 32px;
        height: 32px;
        flex-grow: 0;
        border-radius: 100%;
        border: 1px solid ${palette.neutral[100]};
        flex: 1 0 auto;
        overflow: hidden;
        background-color: ${palette.neutral.white};
    `
)

export const StyledMessageAuthor = styled.h4(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[600]};
        font-weight: 400;
    `
)

export const StyledMessageContent = styled.div(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
        width: 100%;
    `
)

const blinkAnimation = keyframes`
     from, to {
         opacity: 0;
     }
     50% {
         opacity: 1;
     }
 `

export const StyledBlinkingCursor = styled.div(
    ({theme: {palette}}) => css`
        width: 3px;
        border-radius: 4px;
        height: 16px;
        background-color: ${palette.neutral['900']};
        animation: ${blinkAnimation} 1s step-end infinite;
    `
)

export const StyledMessageFooter = styled.div`
    min-height: 32px;
`

export const StyledMessageAIContainer = styled(Flexbox)<{$isActive: boolean}>(
    ({$isActive}) => `
        width: 100%;
        ${
            !$isActive &&
            css`
                &:not(:hover) {
                    & .message-thumbs {
                        opacity: 0;
                    }
                }
            `
        }
    `
)

export const StyledMessageUserContent = styled(Flexbox)`
    white-space: pre-wrap;
    max-width: 100%;
`

export const StyledUserMessageSafeContent = styled.div`
    max-width: 100%;
    overflow-wrap: break-word;
`
