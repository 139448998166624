import {Button} from '@/components/ui/button/Button'
import styled from 'styled-components'

export const StyledThumbButton = styled(Button)`
    width: 32px;
    height: 32px;

    &:not(:disabled):hover {
        background: transparent;
    }
`
