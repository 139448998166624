import {HeaderGrid, HeaderWrapper, StyledExitButtonLink, StyledNewChatButton} from '@components/commons/header/style.ts'
import Logo from '@assets/images/Logo.svg'
import {Container} from '@components/ui/container/Container.tsx'
import {ArrowNarrowUpLeftIcon, Edit05Icon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {useChatbotStore} from '@/features/chatbot/store/store'

export const Header = () => {
    const {t} = useTranslation()
    const conversation = useChatbotStore(state => state.conversation)
    const resetChatbot = useChatbotStore(state => state.resetChatbot)
    const isAnimateConversation = useChatbotStore(state => state.isAnimateConversation)
    const isLoadingConversation = useChatbotStore(state => state.isLoadingConversation)

    return (
        <HeaderWrapper>
            <Container>
                <HeaderGrid>
                    <StyledExitButtonLink href="https://www.scamilloforlanini.rm.it/">
                        <ArrowNarrowUpLeftIcon />
                        {t('commons:back_to_website')}
                    </StyledExitButtonLink>

                    <img src={Logo} alt={'logo'} height={26} />

                    <StyledNewChatButton
                        variant="secondary"
                        disabled={conversation.length == 0 || isLoadingConversation || isAnimateConversation}
                        onClick={resetChatbot}
                    >
                        <Edit05Icon />
                        {t('commons:new_chat')}
                    </StyledNewChatButton>
                </HeaderGrid>
            </Container>
        </HeaderWrapper>
    )
}
