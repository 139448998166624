import * as pages from '@/pages'

export const routes = {
    CHAT: {
        path: '/',
        element: <pages.Chatbot />,
        handle: {
            path: '/',
            paramName: '',
            name: 'routeNames:chatbot'
        }
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}
