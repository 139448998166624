import styled, {css} from 'styled-components'

export const StyledSmallError = styled.small`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.danger['500']};
        ${typography.textSm}
    `}
`

export const StyledSmall = styled.small`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.neutral['500']};
        ${typography.textSm};
        text-align: left;
    `}
`
