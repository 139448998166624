import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledConversation = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        max-width: 640px;
        width: 100%;
        margin: auto;
        height: 100%;

        & div#bottom-conversation {
            width: 100%;
            padding: ${spacing * 2}px 0;
        }
    `
)
