import {ChangeEvent, FC, useMemo, useState} from 'react'
import {StyledAutoscalingTextarea, StyledAutoscalingTextareaContainer, StyledButton} from './style'
import {ArrowUpIcon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {useChatbotStore} from '@/features/chatbot/store/store'
import {SocketEvent} from '@/features/chatbot/types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@/hooks/useWindowDimensions'

interface AutoscalingTextareaProps {
    onSubmit: (event: SocketEvent, message: string) => void
    onFocus?: () => void
    onBlur?: () => void
    disabled?: boolean
}

export const AutoscalingTextarea: FC<AutoscalingTextareaProps> = ({onSubmit, onFocus, onBlur, disabled}) => {
    const [value, setValue] = useState('')
    const [height, setHeight] = useState<number | 'auto'>(24)
    const isLoadingConversation = useChatbotStore(state => state.isLoadingConversation)
    const isAnimateConversation = useChatbotStore(state => state.isAnimateConversation)
    const isEndedConversation = useChatbotStore(state => state.isEndedConversation)
    const {t} = useTranslation()
    const {palette} = useTheme()
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 720, [width])

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.target.rows = 1
        const previousRows = event.target.rows
        const textareaLineHeight = 24
        const currentRows = Math.ceil(event.target.scrollHeight / textareaLineHeight)

        if (currentRows == previousRows) {
            event.target.rows = currentRows
        }

        setHeight(currentRows * textareaLineHeight)
        setValue(event.target.value)
    }

    const handleSubmit = () => {
        if (value) {
            onSubmit('message', value)
            setValue('')
            setHeight('auto')
        }
    }

    const stopSocket = () => {
        console.log('Stop socket')
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key == 'Backspace' || event.key == 'Delete' || (isMobile && event.key == 'Enter')) {
            setHeight('auto')
        }

        if (!isMobile && event.key == 'Enter' && !event.shiftKey && !isLoadingConversation) {
            event.preventDefault()
            if (isAnimateConversation) return

            setHeight('auto')
            handleSubmit()
        }
    }

    return (
        <StyledAutoscalingTextareaContainer align="center">
            <StyledAutoscalingTextarea
                onChange={onChange}
                onKeyDown={handleKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
                $height={height}
                value={value}
                placeholder={!disabled && !isEndedConversation ? t('footer:textarea_placeholder') : ''}
                disabled={disabled || isEndedConversation}
                maxLength={600}
            />

            {isAnimateConversation || isLoadingConversation ? (
                <StyledButton disabled={true} iconOnly size="md" variant="secondary" onClick={stopSocket}>
                    <Spinner size={20} color={palette.neutral[400]} />
                </StyledButton>
            ) : (
                <StyledButton iconOnly size="md" disabled={!value} onClick={handleSubmit}>
                    <ArrowUpIcon />
                </StyledButton>
            )}
        </StyledAutoscalingTextareaContainer>
    )
}
