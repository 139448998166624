import {MUTATION_KEYS} from '@/queryClient'
import {useMutation} from '@tanstack/react-query'
import {
    SendFeedbackConversationOptions,
    SendFeedbackConversationResponse,
    sendFeedbackConversation
} from '@/features/chatbot/services/chatbot.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'

type UseSendFeedbackConversation = {
    onSuccess?: (data: SendFeedbackConversationResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useSendFeedbackConversation = (options?: UseSendFeedbackConversation) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.SEND_FEEDBACK_CONVERSATION],
        mutationFn: (params: SendFeedbackConversationOptions) =>
            parseAxiosPromise({
                axiosPromise: sendFeedbackConversation(params),
                onZodError: captureException,
                responseShape: SendFeedbackConversationResponse
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: data => options?.onSuccess?.(data)
    })
