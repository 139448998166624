import {FC} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledFormStepRow} from '@/features/chatbot/components/open-ticket-modal/style'
import {InputText} from '@/components/commons/input-text/InputText'
import {OPEN_TICKET_FORM_MODEL} from '@/features/chatbot/utils'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {OpenTicketFormSchema} from '@/features/chatbot/components/open-ticket-modal/TicketFormModel'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import 'react-phone-input-2/lib/style.css'

export const InfoFormStep: FC<{isPending: boolean}> = ({isPending}) => {
    const {t} = useTranslation()
    const {
        register,
        formState: {errors, touchedFields}
    } = useFormContext<OpenTicketFormSchema>()

    return (
        <Flexbox gap={4} direction="column">
            <StyledFormStepRow gap={4}>
                <InputText
                    label={`${t(OPEN_TICKET_FORM_MODEL.senderFirstName.label)}*`}
                    placeholder={t(OPEN_TICKET_FORM_MODEL.senderFirstName.label)}
                    type={'text'}
                    maxLength={64}
                    touched={touchedFields.senderFirstName}
                    errorMessage={t(errors.senderFirstName?.message || '')}
                    disabled={isPending}
                    {...register(OPEN_TICKET_FORM_MODEL.senderFirstName.name)}
                />
                <InputText
                    label={`${t(OPEN_TICKET_FORM_MODEL.senderLastName.label)}*`}
                    placeholder={t(OPEN_TICKET_FORM_MODEL.senderLastName.label)}
                    type={'text'}
                    maxLength={64}
                    touched={touchedFields.senderLastName}
                    errorMessage={t(errors.senderLastName?.message || '')}
                    disabled={isPending}
                    {...register(OPEN_TICKET_FORM_MODEL.senderLastName.name)}
                />
            </StyledFormStepRow>

            <InputText
                label={`${t(OPEN_TICKET_FORM_MODEL.email.label)}*`}
                placeholder={t(OPEN_TICKET_FORM_MODEL.email.label)}
                type={'text'}
                maxLength={64}
                touched={touchedFields.email}
                errorMessage={t(errors.email?.message || '')}
                disabled={isPending}
                {...register(OPEN_TICKET_FORM_MODEL.email.name)}
            />

            <InputText
                label={`${t(OPEN_TICKET_FORM_MODEL.phoneNumber.label)}*`}
                placeholder={t(OPEN_TICKET_FORM_MODEL.phoneNumber.label)}
                type={'tel'}
                touched={touchedFields.phoneNumber}
                errorMessage={t(errors.phoneNumber?.message || '')}
                disabled={isPending}
                maxLength={16}
                {...register(t(OPEN_TICKET_FORM_MODEL.phoneNumber.name))}
            />

            <Checkbox
                id={OPEN_TICKET_FORM_MODEL.isDelegating.name}
                label={t(OPEN_TICKET_FORM_MODEL.isDelegating.label)}
                errorMessage={t(errors.isDelegating?.message || '')}
                helpText={t('ticket_modal:form:isDelegatingHelpText')}
                disabled={isPending}
                {...register(OPEN_TICKET_FORM_MODEL.isDelegating.name)}
            />
        </Flexbox>
    )
}
