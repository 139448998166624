import {create} from 'zustand'
import {ChatbotInfo, MessageData, SocketError} from '@/features/chatbot/types'

type ChatbotStore = {
    sessionId: string | null
    conversationId: number | null
    conversation: MessageData[]
    conversationError: SocketError | null
    isLoadingConversation: boolean
    isAnimateConversation: boolean
    isEndedConversation: boolean
    openTicketModal: boolean
    isSatisfyingConversation: boolean | null
    initChatbot: (info: ChatbotInfo) => void
    resetChatbot: () => void
    addMessageToConversation: (message: MessageData) => void
    updateIsLoadingConversation: (isLoading: boolean) => void
    addMessageChunk: (chunk: MessageData) => void
    completeChatbotMessage: (id: number, text: string, isPhoneAvailable: boolean) => void
    setConversationError: (error: SocketError) => void
    addDefaultMessage: (message: 'send_ticket' | 'send_ticket_or_call' | 'show_satisfaction') => void
    hideDefaultMessage: () => void
    showOpenTicketModal: (isVisibile: boolean) => void
    updateMessageSatisfaction: (messageId: number, isSatisfied: boolean) => void
    updateConversationSatisfaction: (isSatisfied: boolean | null) => void
}

const initialStore = {
    sessionId: null,
    conversationId: null,
    conversation: [],
    conversationError: null,
    isLoadingConversation: false,
    isAnimateConversation: false,
    isEndedConversation: false,
    isSatisfyingConversation: null,
    openTicketModal: false
}

export const useChatbotStore = create<ChatbotStore>()(set => ({
    ...initialStore,
    initChatbot: info =>
        set({
            sessionId: info.sessionId,
            conversationId: info.id,
            conversation: [],
            conversationError: null,
            isLoadingConversation: false,
            isEndedConversation: false,
            isAnimateConversation: false
        }),
    resetChatbot: () => set(initialStore),
    addMessageToConversation: message =>
        set(store => ({
            conversation: store.conversation.filter(message => !message.default).concat(message),
            isSatisfyingConversation: store.isSatisfyingConversation == false ? null : store.isSatisfyingConversation
        })),
    updateIsLoadingConversation: isLoading =>
        set({
            isLoadingConversation: isLoading
        }),
    addMessageChunk: chunk =>
        set(store => {
            if (store.conversation.at(-1)?.isAi) {
                const updateConversation = [...store.conversation]

                updateConversation[updateConversation.length - 1] = {
                    ...updateConversation[updateConversation.length - 1],
                    text: updateConversation[updateConversation.length - 1].text.concat(chunk.text)
                }

                return {
                    conversation: updateConversation,
                    isAnimateConversation: true
                }
            } else {
                return {
                    conversation: store.conversation.concat(chunk)
                }
            }
        }),
    completeChatbotMessage: (messageId, text, isPhoneAvailable) =>
        set(store => {
            const updateConversation = [...store.conversation]

            // If BE send us a default message without being preceded by another AI message
            if (!updateConversation[updateConversation.length - 1].isAi) {
                return {
                    isLoadingConversation: false,
                    isAnimateConversation: false
                }
            }

            updateConversation[updateConversation.length - 1] = {
                ...updateConversation[updateConversation.length - 1],
                text,
                messageId,
                isPhoneAvailable
            }

            return {
                conversation: updateConversation,
                isAnimateConversation: false
            }
        }),
    addDefaultMessage: defaultMessage =>
        set(store => {
            return {
                conversation: store.conversation
                    .filter(message => !message.default)
                    .concat({
                        isAi: true,
                        text: '',
                        default: defaultMessage
                    })
            }
        }),
    hideDefaultMessage: () =>
        set(store => {
            return {
                conversation: store.conversation.filter(message => !message.default)
            }
        }),
    setConversationError: error =>
        set({
            conversationError: error,
            isEndedConversation: true
        }),
    showOpenTicketModal: isVisible =>
        set({
            openTicketModal: isVisible
        }),
    updateMessageSatisfaction: (messageId, isSatisfied) =>
        set(store => ({
            conversation: store.conversation.map(message =>
                message.messageId == messageId
                    ? {
                          ...message,
                          isSatisfied
                      }
                    : message
            )
        })),
    updateConversationSatisfaction: isSatisfied =>
        set({
            isSatisfyingConversation: isSatisfied
        })
}))
