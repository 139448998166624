import {Button} from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {
    StyledOpenPolicyModal,
    StyledOpenPolicyModalBody,
    StyledOpenPolicyModalFooter,
    StyledOpenPolicyModalTitle
} from './style'

interface OpenPolicyModalProps {
    acceptPolicy: () => void
}

export const OpenPolicyModal = ({acceptPolicy}: OpenPolicyModalProps) => {
    const {t} = useTranslation()

    return (
        <StyledOpenPolicyModal width={600}>
            <StyledOpenPolicyModalBody gap={6} direction="column">
                <Flexbox direction="column" gap={4}>
                    <StyledOpenPolicyModalTitle>{t('policy_modal:title')}</StyledOpenPolicyModalTitle>
                    <p>{t('policy_modal:text_1')}</p>
                    <strong>{t('policy_modal:text_2')}</strong>
                    <p>{t('policy_modal:text_3')}</p>
                </Flexbox>
            </StyledOpenPolicyModalBody>

            <Divider direction="horizontal" />

            <StyledOpenPolicyModalFooter justify="space-between">
                <ButtonLink variant="ghost" to="/informativa-privacy.pdf" target="_blank">
                    {t('policy_modal:read_policy')}
                </ButtonLink>
                <Button
                    variant="primary"
                    onClick={() => {
                        acceptPolicy()
                    }}
                >
                    {t('policy_modal:accept')}
                </Button>
            </StyledOpenPolicyModalFooter>
        </StyledOpenPolicyModal>
    )
}
