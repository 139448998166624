import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledDefaultCtaMessage = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px 0;
    `
)

export const StyledDividerOr = styled(Flexbox)(
    ({theme: {spacing, typography}}) => css`
        ${typography.textXs};
        width: 100%;
        margin: ${spacing * 4}px 0;
    `
)

export const StyledMessageCtaPhone = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        width: 100%;

        & a {
            cursor: pointer;
            color: ${palette.primary[600]};
            text-decoration: underline;
        }
    `
)
