import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<PublicLayout />}>
            <Route {...routes.NOT_FOUND} />
            <Route {...routes.CHAT} />
        </Route>
    )
)
