import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useChatbotStore} from '@/features/chatbot/store/store'
import {ChatbotUserSearchParam, SocketEvent} from '@/features/chatbot/types'
import {
    StyledChatbotBody,
    StyledChatbotContainer,
    StyledChatbotFooter,
    StyledChatbotFooterWarn,
    StyledChatbotWrapper
} from '@/pages/chatbot/style'
import {AutoscalingTextarea} from '@/features/chatbot/components/autoscaling-textarea/AutoscalingTextarea'
import {WelcomePrompt} from '@/features/chatbot/components/welcome-prompt/WelcomePrompt'
import {socket} from '@/socket'
import {Conversation} from '@/features/chatbot/components/conversation/Conversation'
import {OpenTicketModal} from '@/features/chatbot/components/open-ticket-modal/OpenTicketModal'
import {OpenPolicyModal} from '@/features/chatbot/components/open-policy-modal/OpenPolicyModal'
import {useNewConversation} from '@/features/chatbot/services/useNewConversation'
import {useSocketEvents} from '@/features/chatbot/hooks/useSocketEvents'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'

export const Chatbot = () => {
    const {t} = useTranslation()
    const [isTextareaFocused, setIsTextareaFocused] = useState(false)
    const initChatbot = useChatbotStore(state => state.initChatbot)
    const conversationId = useChatbotStore(state => state.conversationId)
    const sessionId = useChatbotStore(state => state.sessionId)
    const updateIsLoadingConversation = useChatbotStore(state => state.updateIsLoadingConversation)
    const conversation = useChatbotStore(state => state.conversation)
    const addMessageToConversation = useChatbotStore(state => state.addMessageToConversation)
    const conversationError = useChatbotStore(state => state.conversationError)
    const openTicketModal = useChatbotStore(state => state.openTicketModal)
    const showOpenTicketModal = useChatbotStore(state => state.showOpenTicketModal)
    const [openPolicyModal, setOpenPolicyModal] = useState<null | boolean>(null)
    const conversationRef = useRef<HTMLDivElement>(null)
    const addNewConversation = useNewConversation()
    const {searchParams, setSearchParams} = useParsedSearchParams([], ChatbotUserSearchParam)

    const acceptPolicy = () => {
        localStorage.setItem('policyAccepted', 'true')
        setOpenPolicyModal(false)
    }

    // Send message to the server
    const sendMessage = (conversationId: number, event: SocketEvent, message: string) => {
        if (!message.trim()) return

        updateIsLoadingConversation(true)
        addMessageToConversation({text: message, isAi: false})

        socket.emit('message', {
            event,
            message,
            sessionId,
            conversationId
        })
    }

    const onSend = async (event: SocketEvent, message: string) => {
        if (!conversationId) {
            try {
                setSearchParams({ticket: undefined})
                const newConversation = await addNewConversation.mutateAsync({type: searchParams.user})
                initChatbot(newConversation.data)
                sendMessage(newConversation.data.id, event, message)
            } catch (error) {
                console.log(error)
            }
        } else {
            sendMessage(conversationId, event, message)
        }
    }

    useEffect(() => {
        const isPolicyAccepted = localStorage.getItem('policyAccepted') === 'true'
        setOpenPolicyModal(!isPolicyAccepted)

        if (openPolicyModal === false && searchParams.ticket === 'true') {
            showOpenTicketModal(true)
        }
    }, [openPolicyModal])

    // Auto scroll
    useEffect(() => {
        conversationRef.current?.scrollIntoView({
            behavior: 'instant',
            block: 'end',
            inline: 'end'
        })
    }, [conversation])

    // Subscribe to the message event
    useSocketEvents()

    return (
        <StyledChatbotWrapper>
            <StyledChatbotContainer fullWidth>
                <StyledChatbotBody>
                    {conversation.length == 0 ? (
                        <WelcomePrompt onSend={onSend} isTextareaFocused={isTextareaFocused} hasSuggestions={false} />
                    ) : (
                        <Conversation ref={conversationRef} />
                    )}
                </StyledChatbotBody>

                <StyledChatbotFooter direction="column" gap={2.5} align="center">
                    <AutoscalingTextarea
                        onSubmit={onSend}
                        disabled={!!conversationError}
                        onFocus={() => setIsTextareaFocused(true)}
                        onBlur={() => setIsTextareaFocused(false)}
                    />
                    <StyledChatbotFooterWarn>{t('footer:warning')}</StyledChatbotFooterWarn>
                </StyledChatbotFooter>

                {openTicketModal && <OpenTicketModal />}
                {openPolicyModal && <OpenPolicyModal acceptPolicy={acceptPolicy} />}
            </StyledChatbotContainer>
        </StyledChatbotWrapper>
    )
}

Chatbot.displayName = 'Chatbot'
