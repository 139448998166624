import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const HeaderWrapper = styled.div`
    ${({theme: {palette}}) => css`
        box-sizing: border-box;
        background: ${palette.neutral.white};
        border-bottom: 1px solid ${palette.neutral['200']};
        height: 64px;
    `}
`

export const HeaderGrid = styled(Flexbox)`
    ${({theme: {mediaQueries}}) => css`
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        ${mediaQueries.m} {
            & > img {
                display: none;
            }
        }
    `}
`

export const StyledNewChatButton = styled(Button)(
    ({theme: {palette}}) => css`
        background-color: ${palette.primary[50]};
        color: ${palette.primary[700]};
        border-radius: 100px;
        border-color: ${palette.primary[50]};

        &:disabled {
            background: ${palette.neutral['50']};
            border: 1px solid ${palette.neutral['200']};
            cursor: not-allowed;
            box-shadow: none;
        }
    `
)

export const StyledExitButtonLink = styled.a(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm};
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        word-break: break-word;
        color: ${palette.primary[700]};
        border-radius: 100px;
        background: transparent;
        & svg {
            width: 20px;
        }
    `
)
