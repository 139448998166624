import styled, {css, DefaultTheme} from 'styled-components'
import {Link} from 'react-router-dom'

type StyledButtonLinkProps = {
    $disabled: boolean
    $fullWidth: boolean
    $iconOnly: boolean
    $variant: 'primary' | 'secondary' | 'tertiary' | 'ghost'
    $size: 'sm' | 'md' | 'lg' | 'xl'
}

export const StyledButtonLink = styled(Link)<StyledButtonLinkProps>`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: none;
        border-radius: 8px;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 600;
        word-break: break-word;
        & svg {
            width: 20px;
        }
    `}
    ${({$variant, $disabled, theme}) => ColorStyles({theme, $disabled})[$variant]};
    ${({$size, $fullWidth, $iconOnly, theme}) => SizeStyles({theme: theme, $fullWidth, $iconOnly})[$size]};
`

type ColorsProps = {
    theme: DefaultTheme
    $disabled: boolean
}

const ColorStyles = ({theme: {palette, shadows}, $disabled}: ColorsProps) => ({
    primary: css`
        color: ${palette.neutral.white};
        background: ${$disabled ? palette.primary['200'] : palette.primary['600']};
        border: 1px solid ${$disabled ? palette.primary['200'] : palette.primary['600']};
        box-shadow: ${shadows.xs};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.primary['700']};
                    border: 1px solid ${palette.primary['700']};
                }
            `}
        &:focus {
            box-shadow: ${!$disabled && `${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
    `,
    secondary: css`
        background: ${$disabled ? palette.neutral[200] : palette.neutral.white};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['700']};
        border: 1px solid ${palette.neutral['300']};
        box-shadow: ${shadows.xs};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.neutral['50']};
                }
            `}
        &:focus {
            box-shadow: ${$disabled ? 'none' : `${shadows.xs}, 0px 0px 0px 4px ${palette.neutral['100']}`};
        }
    `,
    ghost: css`
        background: ${$disabled ? palette.neutral.white : 'transparent'};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['700']};
        pointer-events: ${$disabled ? 'none' : 'auto'};
        ${!$disabled &&
        `
                &:hover {
                    background: ${palette.neutral['50']};
                }
            `}
    `,
    tertiary: css`
        background: ${$disabled ? palette.neutral.white : 'transparent'};
        color: ${$disabled ? palette.neutral['300'] : palette.neutral['600']};
        font-weight: 500;
        text-decoration: underline;
        pointer-events: ${$disabled ? 'none' : 'auto'};
    `
})

type SizesProps = {
    $fullWidth: boolean
    $iconOnly: boolean
    theme: DefaultTheme
}

const SizeStyles = ({theme: {spacing, typography}, $fullWidth, $iconOnly}: SizesProps) => ({
    /*the Y padding must be ((min-height - line height) / 2)*/
    sm: css`
        min-height: 36px;
        width: ${$iconOnly ? '36px' : $fullWidth ? '100%' : 'auto'};
        ${!$iconOnly &&
        css`
            padding: ${spacing * 2}px ${spacing * 3.5}px;
        `};
        ${typography.textSm}
    `,
    md: css`
        min-height: 40px;
        width: ${$iconOnly ? '40px' : $fullWidth ? '100%' : 'auto'};
        ${!$iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4}px;
        `};
        ${typography.textSm}
    `,
    lg: css`
        min-height: 44px;
        width: ${$iconOnly ? '44px' : $fullWidth ? '100%' : 'auto'};
        ${$iconOnly &&
        css`
            width: 44px;
        `}
        ${!$iconOnly &&
        css`
            padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        `};
        ${typography.textMd}
    `,
    xl: css`
        min-height: 60px;
        width: ${$iconOnly ? '60px' : $fullWidth ? '100%' : 'auto'};
        ${!$iconOnly &&
        css`
            padding: ${spacing * 4}px ${spacing * 7}px;
        `};
        ${typography.textLg}
    `
})
