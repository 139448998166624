import {Outlet} from 'react-router-dom'
import React from 'react'
import {StyledPublicLayout} from '@/layouts/public-layout/style'
import {Header} from '@/components/commons/header/Header'

export const PublicLayout: React.FC = () => {
    return (
        <StyledPublicLayout>
            <Header />
            <Outlet />
        </StyledPublicLayout>
    )
}
