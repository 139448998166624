import {ThumbsDownIcon, ThumbsUpIcon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'
import {useSendFeedbackMessage} from '@/features/chatbot/services/useSendFeedbackMessage'
import toast from 'react-hot-toast'
import {useChatbotStore} from '@/features/chatbot/store/store'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import {MessageData} from '@/features/chatbot/types'
import {StyledThumbButton} from '@/features/chatbot/components/message-thumbs-buttons/style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const MessageThumbsButtons: FC<{message: MessageData}> = ({message}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const conversationId = useChatbotStore(state => state.conversationId)
    const updateMessageSatisfaction = useChatbotStore(state => state.updateMessageSatisfaction)
    // This is a warkaround to allow the update of thumbs. Remove this after backend updates
    const [isSatisfied, setIsSatisfied] = useState<boolean | null>(null)

    const sendFeedback = useSendFeedbackMessage({
        onSuccess: () => {
            updateMessageSatisfaction(message.messageId as number, !!isSatisfied)
            toast.success(t('success:feedback_sent'))
        },
        onError: () => {
            toast.error(t('errors:default'))
            setIsSatisfied(null)
        }
    })

    const onSendFeedback = (isSatisfied: boolean, conversationMessageId: number) => {
        if (!conversationId) return

        setIsSatisfied(isSatisfied)

        sendFeedback.mutate({
            urlParams: {
                conversationId,
                conversationMessageId
            },
            payload: {
                isSatisfied
            }
        })
    }

    return (
        <Flexbox className="message-thumbs">
            <StyledThumbButton
                iconOnly
                size="sm"
                variant="ghost"
                onClick={() => onSendFeedback(true, message.messageId as number)}
                disabled={sendFeedback.isPending}
            >
                <ThumbsUpIcon color={message.isSatisfied == true ? palette.success[600] : palette.neutral[600]} />
            </StyledThumbButton>
            <StyledThumbButton
                iconOnly
                size="sm"
                variant="ghost"
                onClick={() => onSendFeedback(false, message.messageId as number)}
            >
                <ThumbsDownIcon color={message.isSatisfied == false ? palette.danger[600] : palette.neutral[600]} />
            </StyledThumbButton>
        </Flexbox>
    )
}
