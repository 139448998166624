import {useChatbotStore} from '@/features/chatbot/store/store'
import {socket} from '@/socket'
import {useEffect} from 'react'

type StatusEvent = {
    chatbotMessage: string
    isPhoneAvailable: boolean
    messageId: number
    showSatisfaction: boolean
    showSendTicket: boolean
}

export const useSocketEvents = () => {
    const updateIsLoadingConversation = useChatbotStore(state => state.updateIsLoadingConversation)
    const addMessageChunk = useChatbotStore(state => state.addMessageChunk)
    const completeChatbotMessage = useChatbotStore(state => state.completeChatbotMessage)
    const addDefaultMessage = useChatbotStore(state => state.addDefaultMessage)
    const setConversationError = useChatbotStore(state => state.setConversationError)
    const conversationId = useChatbotStore(state => state.conversationId)

    useEffect(() => {
        const intervalId = setInterval(() => {
            socket.emit('ping')
        }, 60000)

        socket.once('pong', data => {
            if (!data.sessionEnabled) {
                console.log(data)
            }
        })

        socket.on('message', (data: string) => {
            updateIsLoadingConversation(false)

            if (typeof data == 'string') {
                addMessageChunk({text: data, isAi: true})
            }
        })

        socket.on('status', (data: StatusEvent) => {
            if (data.messageId) {
                completeChatbotMessage(data.messageId, data.chatbotMessage, data.isPhoneAvailable)
            }

            if (data.showSatisfaction) {
                addDefaultMessage('show_satisfaction')
            }

            if (data.showSendTicket && !data.isPhoneAvailable) {
                addDefaultMessage('send_ticket')
            }

            if (data.showSendTicket && data.isPhoneAvailable) {
                addDefaultMessage('send_ticket_or_call')
            }
        })

        socket.on('error', data => {
            setConversationError(data)
            updateIsLoadingConversation(false)
        })

        return () => {
            socket.off('pong')
            socket.off('message')
            socket.off('status')
            clearInterval(intervalId)
        }
    }, [conversationId])
}
