import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    CONVERSATION: 'conversation'
} as const

export const MUTATION_KEYS = {
    NEW_CONVERSATION: 'new-conversation',
    SEND_FEEDBACK_MESSAGE: 'send-feedback-message',
    SEND_FEEDBACK_CONVERSATION: 'send-feedback-conversation',
    OPEN_TICKET: 'open-ticket'
} as const
