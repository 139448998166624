import {FC} from 'react'
import ReactMarkdown from 'react-markdown'
import {StyledMarkdownText} from '@/components/commons/markdown-text/style'

interface MarkdownTextProps {
    content: string
}

export const MarkdownText: FC<MarkdownTextProps> = ({content}) => (
    <StyledMarkdownText>
        <ReactMarkdown>{content}</ReactMarkdown>
    </StyledMarkdownText>
)
