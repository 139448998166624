import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_mark.svg'
import {
    StyledWelcomePromptTitle,
    StyledExampleAnswersGrid,
    StyledExampleAnswerItem,
    StyledExampleAnswerItemTitle,
    StyledExampleAnswerItemDescription,
    StyledWelcomeMessage,
    StyledScrollableExampleAnswers,
    WelcomePromptContainer,
    StyledWelcomePromptParagraph,
    StyledWelcomeMessageContent,
    StyledExampleAnswerScroller
} from '@/features/chatbot/components/welcome-prompt/style'
import {exampleAnswers} from '@/features/chatbot/utils'
import {SocketEvent} from '@/features/chatbot/types'
import {FC, useMemo} from 'react'
import {useChatbotStore} from '../../store/store'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {ScrollArea} from '@/components/commons/scroll-area/ScrollArea'
import {Scrollbar} from '@/components/ui/scrollbar/Scrollbar'

interface WelcomePromptProps {
    hasSuggestions: boolean
    onSend: (event: SocketEvent, message: string) => void
    isTextareaFocused: boolean
}

export const WelcomePrompt: FC<WelcomePromptProps> = ({onSend, hasSuggestions, isTextareaFocused}) => {
    const {t} = useTranslation()
    const conversationError = useChatbotStore(state => state.conversationError)
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width <= 768, [width])

    return (
        <WelcomePromptContainer direction="column">
            <StyledWelcomeMessage align="center" justify="center" gap={8} direction="column">
                <img src={Logo} alt={t('commons:title')} height={56} />
                <StyledWelcomeMessageContent gap={2} direction="column" align="center">
                    <StyledWelcomePromptTitle>{t('welcome:title')}</StyledWelcomePromptTitle>
                    <StyledWelcomePromptParagraph>{t('welcome:paragraph')}</StyledWelcomePromptParagraph>
                </StyledWelcomeMessageContent>
            </StyledWelcomeMessage>

            {hasSuggestions &&
                (isMobile ? (
                    <StyledExampleAnswerScroller>
                        <ScrollArea scrollbar={<Scrollbar orientation="horizontal" />}>
                            <StyledScrollableExampleAnswers gap={4}>
                                {!isTextareaFocused &&
                                    exampleAnswers.map((answerLabel, index) => (
                                        <StyledExampleAnswerItem
                                            key={index}
                                            $isError={!!conversationError}
                                            onClick={() =>
                                                !conversationError &&
                                                onSend('message', t(`welcome:examples:${answerLabel}:text`))
                                            }
                                        >
                                            <StyledExampleAnswerItemTitle>
                                                {t(`welcome:examples:${answerLabel}:title`)}
                                            </StyledExampleAnswerItemTitle>
                                            <StyledExampleAnswerItemDescription>
                                                {t(`welcome:examples:${answerLabel}:subtitle`)}
                                            </StyledExampleAnswerItemDescription>
                                        </StyledExampleAnswerItem>
                                    ))}
                            </StyledScrollableExampleAnswers>
                        </ScrollArea>
                    </StyledExampleAnswerScroller>
                ) : (
                    <StyledExampleAnswersGrid>
                        {exampleAnswers.map((answerLabel, index) => (
                            <StyledExampleAnswerItem
                                key={index}
                                $isError={!!conversationError}
                                onClick={() =>
                                    !conversationError && onSend('message', t(`welcome:examples:${answerLabel}:text`))
                                }
                            >
                                <StyledExampleAnswerItemTitle>
                                    {t(`welcome:examples:${answerLabel}:title`)}
                                </StyledExampleAnswerItemTitle>
                                <StyledExampleAnswerItemDescription>
                                    {t(`welcome:examples:${answerLabel}:subtitle`)}
                                </StyledExampleAnswerItemDescription>
                            </StyledExampleAnswerItem>
                        ))}
                    </StyledExampleAnswersGrid>
                ))}
        </WelcomePromptContainer>
    )
}
