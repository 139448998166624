import {useChatbotStore} from '@/features/chatbot/store/store'
import {Message} from '@/features/chatbot/components/message/Message'
import {StyledConversation} from '@/features/chatbot/components/conversation/style'
import {useTranslation} from 'react-i18next'
import {DefaultCtaMessage} from '@/features/chatbot/components/default-cta-message/DefaultCtaMessage'
import {MessageThumbsButtons} from '@/features/chatbot/components/message-thumbs-buttons/MessageThumbsButtons'
import {StyledMessageFooter} from '@/features/chatbot/components/message/style'
import {forwardRef} from 'react'

export const Conversation = forwardRef<HTMLDivElement>((_, ref) => {
    const {t} = useTranslation()
    const isLoadingConversation = useChatbotStore(state => state.isLoadingConversation)
    const conversationError = useChatbotStore(state => state.conversationError)
    const conversation = useChatbotStore(state => state.conversation)
    const resetChatbot = useChatbotStore(state => state.resetChatbot)
    const isEndedConversation = useChatbotStore(state => state.isEndedConversation)

    return (
        <StyledConversation direction="column" gap={2}>
            {conversation.map((message, index) => (
                <Message
                    key={index}
                    message={message}
                    isLoading={isLoadingConversation}
                    isLastMessage={index == conversation.length - 1}
                    endSlot={
                        <StyledMessageFooter>
                            {message.default ? (
                                <DefaultCtaMessage defaultMessage={message.default} />
                            ) : (
                                !isEndedConversation && message.messageId && <MessageThumbsButtons message={message} />
                            )}
                        </StyledMessageFooter>
                    }
                />
            ))}

            {isLoadingConversation && (
                <Message isLoading message={{isAi: true, text: ''}} endSlot={<StyledMessageFooter />} />
            )}

            {conversationError && (
                <Message
                    message={{
                        isAi: true,
                        text: t(`errors:conversation`),
                        callback: {
                            onClick: resetChatbot,
                            label: t('commons:new_chat')
                        }
                    }}
                />
            )}

            <div ref={ref} id="bottom-conversation" />
        </StyledConversation>
    )
})
