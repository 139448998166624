import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledOpenPolicyModal = styled(Modal)(
    ({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.m} {
            max-width: 100%;
            bottom: 0;
            left: 0;
            top: unset;
            max-height: 100%;
            transform: unset;
            width: 100%;
            max-width: unset;
        }
    `
)

export const StyledOpenPolicyModalHeader = styled.div(
    ({theme: {palette, typography}}) => css`
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        min-height: 56px;
        color: ${palette.neutral[900]};

        & h3 {
            ${typography.textSm};
        }
    `
)

export const StyledOpenPolicyModalFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        ${spacing * 6}px;
    `
)

export const StyledOpenPolicyModalBody = styled(Flexbox)(
    ({theme: {spacing, mediaQueries, palette, typography}}) => css`
        padding: ${spacing * 6}px;
        flex: 1;
        color: ${palette.neutral[900]};
        line-height: 150%;
        font-size: ${typography.textMd} & strong {
            font-weight: 700;
        }

        ${mediaQueries.m} {
            min-height: unset;
            overflow-y: scroll;
            font-size: ${typography.textSm};
        }
    `
)

export const StyledOpenPolicyModalTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.textXl};
        color: ${palette.neutral[900]};
        font-weight: 600;
    `
)
