import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/theme.ts'
import {router} from './router.tsx'
import {Toaster} from 'react-hot-toast'

export default function App() {
    const themeName = 'light' //get it from the app reducer

    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <StyleSheetManager>
                <>
                    <Toaster />
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </>
            </StyleSheetManager>
        </ThemeProvider>
    )
}
