import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'
import GeistRegular from '@/assets/fonts/Geist-Regular.woff2'
import GeistMedium from '@/assets/fonts/Geist-Medium.woff2'
import GeistSemibold from '@/assets/fonts/Geist-SemiBold.woff2'

const ResetStyles = createGlobalStyle`
  * {
  '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
  ' scrollbar-width': 'none' /* for Firefox */,
  }

  @font-face {
    font-family: 'Geist';
    font-weight: 400;
    src: url(${GeistRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Geist';
    font-weight: 500;
    src: url(${GeistMedium}) format('woff2');
  }

  @font-face {
    font-family: 'Geist';
    font-weight: 600;
    src: url(${GeistSemibold}) format('woff2');
  }

  html{
    height: 100%;
    font-size: 16px;
    font-family: 'Geist', sans-serif;
    color: ${palette.light.neutral[500]};
    background-color: ${palette.light.neutral.white};
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }
  
  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }
  label, input, textarea, select, button {
    font-family: inherit;
  }
  
  textarea {
    overflow-wrap: break-word;
  }
  
  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }
  
  svg {
    flex-shrink: 0;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }
  
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

export default withTheme(ResetStyles)
