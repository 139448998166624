import styled, {css, DefaultTheme} from 'styled-components'
import {InputWrapper} from '@/components/ui/input-wrapper/InputWrapper'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CSSProperties} from 'react'

export const StyledInputText = styled(Flexbox)<{$width: CSSProperties['width']}>(
    ({$width}) => css`
        width: ${$width};

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;
    `
)

export const StyledInputWrapper = styled(InputWrapper)<{size: 'sm' | 'md' | 'lg'}>(
    ({theme, size}) => InputSizeStyles(theme)[size]
)

const InputSizeStyles = ({spacing}: DefaultTheme) => ({
    sm: css`
        height: 40px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3}px;
    `,
    md: css`
        height: 44px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 3.5}px;
    `,
    lg: css`
        height: 48px;
        padding: 0 ${spacing * 9}px 0 ${spacing * 4}px;
    `
})

export const StyledInputIconGroup = styled(Flexbox)`
    width: 100%;
`

export const StyledInput = styled.input`
    ${({theme: {typography, palette}}) => css`
        flex: 1;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: ${palette.neutral['900']};
        ${typography.textMd}

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${palette.neutral['400']};
        }
        &:disabled {
            cursor: not-allowed;
        }
    `}
`

type StyledVisibleToggleProps = {
    disabled?: boolean
    size: 'sm' | 'md' | 'lg'
}
export const StyledVisibleToggle = styled.div<StyledVisibleToggleProps>`
    ${({disabled, theme: {palette}}) => css`
        color: ${palette.neutral['700']};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        border: 1px solid ${palette.neutral['300']};
        border-radius: 0 8px 8px 0;
        border-left: none;
    `}
    ${({size, theme}) => VisibleToggleSizeStyles(theme)[size]};
`

const VisibleToggleSizeStyles = ({spacing}: DefaultTheme) => ({
    sm: css`
        height: 40px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `,
    md: css`
        height: 44px;
        padding: ${spacing * 2.5}px ${spacing * 3}px;
    `,
    lg: css`
        height: 48px;
        padding: ${spacing * 2}px ${spacing * 3}px;
    `
})
