import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const WelcomePromptContainer = styled(Flexbox)`
    max-width: 640px;
    margin: auto;
    width: 100%;
    height: 100%;
`

export const StyledWelcomeMessage = styled(Flexbox)`
    flex: 1;
    width: 100%;
`

export const StyledWelcomeMessageContent = styled(Flexbox)`
    max-width: 388px;
`

export const StyledWelcomePromptTitle = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.displayXs};
        color: ${palette.neutral[900]};
        font-weight: 600;
    `
)

export const StyledWelcomePromptMessage = styled.h1(
    ({theme: {palette, typography}}) => css`
        ${typography.displayXs};
        color: ${palette.neutral[900]};
        font-weight: 600;
    `
)

export const StyledWelcomePromptParagraph = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 400;
        text-align: center;
        color: ${palette.neutral[700]};
    `
)

export const StyledExampleAnswersGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: ${spacing * 6}px ${spacing * 4}px;
        gap: ${spacing * 3}px;
        width: 100%;
    `
)

export const StyledExampleAnswerScroller = styled.div`
    max-width: 100%;
`

export const StyledExampleAnswerItem = styled.div<{$isError?: boolean}>(
    ({theme: {spacing, palette, mediaQueries}, $isError: isError}) => css`
        cursor: ${isError ? 'not-allowed' : 'pointer'};
        padding: ${spacing * 3}px ${spacing * 4}px;
        background: ${palette.neutral.white};
        opacity: ${isError ? '0.5' : '1'};
        border-radius: 8px;
        border: 1px solid ${palette.neutral[200]};
        transition: 200ms ease-in-out;

        ${!isError &&
        css`
            &:hover {
                border-color: ${palette.neutral[300]};
            }
        `}

        ${mediaQueries.m} {
            width: max-content;
        }
    `
)

export const StyledExampleAnswerItemTitle = styled.h4(
    ({theme: {typography, palette}}) => css`
        ${typography.textMd};
        font-weight: 600;
        color: ${palette.neutral[900]};
    `
)

export const StyledExampleAnswerItemDescription = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textMd};
        font-weight: 400;
        color: ${palette.neutral[600]};
    `
)

export const StyledScrollableExampleAnswers = styled(Flexbox)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 4}px 0;

        ${mediaQueries.m} {
            padding: ${spacing * 4}px;
        }
    `
)
