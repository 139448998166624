import {Container} from '@/components/ui/container/Container'
import styled, {css} from 'styled-components'
import bg from '@assets/images/dot-pattern-bg.png'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledChatbotWrapper = styled.div(
    ({theme: {palette}}) => css`
        background-color: ${palette.neutral['50']};
        background-image: url(${bg});
    `
)

export const StyledChatbotContainer = styled(Container)`
    display: grid;
    grid-template-rows: 1fr auto;
    padding: 0;
    width: 100%;
`

export const StyledChatbotBody = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior: none;
`

export const StyledChatbotFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border-radius: 24px 24px 0 0;
        background: ${palette.neutral.white};
        max-width: 640px;
        margin: auto;
        width: 100%;
        box-shadow:
            0px 4px 6px -4px ${palette.neutral.black}1A,
            0px 10px 15px -3px ${palette.neutral.black}1A,
            0px -9px 140px 34px ${palette.primary['500']}14;
    `
)

export const StyledChatbotFooterWarn = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['600']};
        text-align: center;
    `
)
