import {create} from 'zustand'
import {AuthState} from '@/features/auth/types.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@/features/auth/constants.ts'

type AuthStoreActions = {
    reset: () => void
}

export const useAuthStore = create<Partial<AuthState> & AuthStoreActions>()(set => ({
    user: null,
    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || undefined,
    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
    reset: () => set({user: null, accessToken: undefined, refreshToken: undefined})
}))
