import axiosInstance from '@/axiosInstance'
import {z} from 'zod'
import {zfd} from 'zod-form-data'
import {ChatbotUserSearchParam} from '../types'

export const AddConversationResponse = z.object({
    id: z.number(),
    sessionId: z.string()
})
export type AddConversationResponse = z.infer<typeof AddConversationResponse>

export const AddConversationOptions = z.object({
    type: ChatbotUserSearchParam.shape['user']
})
export type AddConversationOptions = z.infer<typeof AddConversationOptions>

export const addConversation = (payload: AddConversationOptions) => axiosInstance.post('/conversations', payload)

// Send feedback regarding the message
export const SendFeedbackMessageOptions = z.object({
    urlParams: z.object({
        conversationId: z.number(),
        conversationMessageId: z.number()
    }),
    payload: z.object({
        isSatisfied: z.boolean()
    })
})
export type SendFeedbackMessageOptions = z.infer<typeof SendFeedbackMessageOptions>

export const SendFeedbackMessageResponse = z.object({
    id: z.number(),
    message: z.string()
})
export type SendFeedbackMessageResponse = z.infer<typeof SendFeedbackMessageResponse>

export const sendFeedbackMessage = ({urlParams, payload}: SendFeedbackMessageOptions) =>
    axiosInstance.patch(`/conversations/${urlParams.conversationId}/messages/${urlParams.conversationMessageId}`, {
        isSatisfied: payload.isSatisfied
    })

// Send feedback regarding the conversation
export const SendFeedbackConversationOptions = z.object({
    urlParams: z.object({
        conversationId: z.number()
    }),
    payload: z.object({
        isSatisfied: z.boolean()
    })
})
export type SendFeedbackConversationOptions = z.infer<typeof SendFeedbackConversationOptions>

export const SendFeedbackConversationResponse = z.object({
    id: z.number(),
    satisfaction: z.boolean().optional()
})
export type SendFeedbackConversationResponse = z.infer<typeof SendFeedbackConversationResponse>

export const sendFeedbackConversation = ({urlParams, payload}: SendFeedbackConversationOptions) =>
    axiosInstance.patch(`/conversations/${urlParams.conversationId}`, {
        isSatisfied: payload.isSatisfied
    })

// Send ticket
export const OpenTicketPayload = zfd.formData({
    conversationId: zfd.numeric(),
    senderFirstName: zfd.text(),
    senderLastName: zfd.text(),
    patientFirstName: zfd.text().optional(),
    patientLastName: zfd.text().optional(),
    email: zfd.text(z.string().email()),
    phoneNumber: zfd.text(),
    content: zfd.text(),
    type: zfd.text(),
    identityDocument: z.instanceof(File).optional(),
    delegation: z.instanceof(File).optional()
})
export type OpenTicketPayload = z.infer<typeof OpenTicketPayload>

export const OpenTicketResponse = z.object({
    id: z.number(),
    conversationId: z.number()
})
export type OpenTicketResponse = z.infer<typeof OpenTicketResponse>

export const openTicket = (payload: OpenTicketPayload) =>
    axiosInstance.post(`/tickets`, payload, {headers: {'Content-Type': 'multipart/form-data'}})
