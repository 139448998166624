import {MUTATION_KEYS} from '@/queryClient'
import {useMutation} from '@tanstack/react-query'
import {
    SendFeedbackMessageOptions,
    SendFeedbackMessageResponse,
    sendFeedbackMessage
} from '@/features/chatbot/services/chatbot.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types'
import {parseAxiosPromise} from '@/axiosInstance'
import {captureException} from '@sentry/react'

type UseSendFeedbackMessage = {
    onSuccess?: (data: SendFeedbackMessageResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useSendFeedbackMessage = (options?: UseSendFeedbackMessage) =>
    useMutation({
        mutationKey: [MUTATION_KEYS.SEND_FEEDBACK_MESSAGE],
        mutationFn: (params: SendFeedbackMessageOptions) =>
            parseAxiosPromise({
                axiosPromise: sendFeedbackMessage(params),
                onZodError: captureException,
                responseShape: SendFeedbackMessageResponse
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: data => options?.onSuccess?.(data)
    })
