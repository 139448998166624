import {FieldErrors} from 'react-hook-form'
import {OpenTicketFormSchema} from './components/open-ticket-modal/TicketFormModel'
import {FormTicketStep} from './types'

export const exampleAnswers = ['example_1', 'example_2', 'example_3', 'example_4']

export const phoneRegex = new RegExp(/^([+]\d{2})?\d{10}$/)

export const sizeInMB = (sizeInBytes: number, decimalsNum = 2) => {
    const result = sizeInBytes / (1024 * 1024)
    return +result.toFixed(decimalsNum)
}

export const MAX_UPLOAD_SIZE = 8 // MB
export const ACCEPTED_FILE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'application/pdf': ['.pdf']
}
export const MIN_CONTENT_LENGTH = 20
export const MAX_CONTENT_LENGTH = 2400

export const OPEN_TICKET_FORM_MODEL = {
    senderFirstName: {
        name: 'senderFirstName',
        label: 'ticket_modal:form:senderFirstName'
    },
    senderLastName: {
        name: 'senderLastName',
        label: 'ticket_modal:form:senderLastName'
    },
    patientFirstName: {
        name: 'patientFirstName',
        label: 'ticket_modal:form:patientFirstName'
    },
    patientLastName: {
        name: 'patientLastName',
        label: 'ticket_modal:form:patientLastName'
    },
    email: {
        name: 'email',
        label: 'ticket_modal:form:email'
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: 'ticket_modal:form:phoneNumber'
    },
    isDelegating: {
        name: 'isDelegating',
        label: 'ticket_modal:form:isDelegating'
    },
    content: {
        name: 'content',
        label: 'ticket_modal:form:content'
    },
    identityDocumentFile: {
        name: 'identityDocumentFile',
        label: 'ticket_modal:form:identityDocumentFile'
    },
    signedDelegationFile: {
        name: 'signedDelegationFile',
        label: 'ticket_modal:form:signedDelegationFile'
    },
    type: {
        name: 'type',
        label: ''
    }
} as const

export const TICKET_TYPES_MODEL: {value: OpenTicketFormSchema['type']; label: string}[] = [
    {
        value: 'praise',
        label: 'ticket_modal:form:type:praise'
    },
    {
        value: 'complaint',
        label: 'ticket_modal:form:type:complaint'
    },
    {
        value: 'suggestion',
        label: 'ticket_modal:form:type:suggestion'
    },
    {
        value: 'info',
        label: 'ticket_modal:form:type:info'
    },
    {
        value: 'help',
        label: 'ticket_modal:form:type:help'
    },
    {
        value: 'refund',
        label: 'ticket_modal:form:type:refund'
    }
]

export const checkValidityStep = (step: FormTicketStep, formData: OpenTicketFormSchema, errors: FieldErrors) => {
    if (Object.keys(errors).length) return false

    switch (step) {
        case 'info':
            return formData.senderFirstName && formData.senderLastName && formData.email && formData.phoneNumber
        case 'delegation':
            return (
                formData.patientFirstName &&
                formData.patientLastName &&
                formData.signedDelegationFile &&
                formData.identityDocumentFile
            )
        case 'content':
            return formData.type
        default:
            return false
    }
}

export const adaptTicketToPostMutation = (conversationId: number, data: OpenTicketFormSchema) => {
    const ticketFormData = new FormData()
    ticketFormData.append('conversationId', conversationId?.toString())
    ticketFormData.append('senderFirstName', data.senderFirstName)
    ticketFormData.append('senderLastName', data.senderLastName)
    ticketFormData.append('email', data.email)
    ticketFormData.append('phoneNumber', data.phoneNumber)
    ticketFormData.append('content', data.content.trim())
    ticketFormData.append('type', data.type)

    if (
        data.isDelegating &&
        data.patientFirstName &&
        data.patientLastName &&
        data.identityDocumentFile &&
        data.signedDelegationFile
    ) {
        ticketFormData.append('identityDocument', data.identityDocumentFile)
        ticketFormData.append('delegation', data.signedDelegationFile)
        ticketFormData.append('patientFirstName', data.patientFirstName)
        ticketFormData.append('patientLastName', data.patientLastName)
    }

    return ticketFormData
}
