import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/Icon'
import {useChatbotStore} from '@/features/chatbot/store/store'
import {useTranslation} from 'react-i18next'
import {
    StyledFormStepSubtitle,
    StyledFormStepTitle,
    StyledOpenTicketModal,
    StyledOpenTicketModalBody,
    StyledOpenTicketModalFooter,
    StyledOpenTicketModalForm,
    StyledOpenTicketModalHeader
} from '@/features/chatbot/components/open-ticket-modal/style'
import {Divider} from '@/components/ui/divider/Divider'
import {useMemo, useState} from 'react'
import {FormProvider, SubmitHandler, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ChatbotUserSearchParam, FormTicketStep} from '@/features/chatbot/types'
import {OPEN_TICKET_FORM_MODEL, adaptTicketToPostMutation, checkValidityStep} from '@/features/chatbot/utils'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useOpenTicket} from '@/features/chatbot/services/useOpenTicket'
import {OpenTicketPayload} from '@/features/chatbot/services/chatbot.http'
import toast from 'react-hot-toast'
import {OpenTicketFormSchema} from './TicketFormModel'
import {InfoFormStep} from './InfoFormStep'
import {DelegationFormStep} from './DelegationFormStep'
import {ContentFormStep} from './ContentFormStep'
import {useNewConversation} from '../../services/useNewConversation'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'

export const OpenTicketModal = () => {
    const [step, setStep] = useState<FormTicketStep>('info')
    const {t} = useTranslation()
    const conversationId = useChatbotStore(state => state.conversationId)
    const showOpenTicketModal = useChatbotStore(state => state.showOpenTicketModal)
    const resetChatbot = useChatbotStore(state => state.resetChatbot)
    const {searchParams, setSearchParams} = useParsedSearchParams([], ChatbotUserSearchParam)

    const methods = useForm<OpenTicketFormSchema>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: zodResolver(OpenTicketFormSchema),
        defaultValues: {
            isDelegating: false,
            identityDocumentFile: null,
            signedDelegationFile: null
        }
    })

    const {
        getValues,
        control,
        handleSubmit,
        watch,
        formState: {errors}
    } = methods

    const isDelegating = useWatch({control, name: OPEN_TICKET_FORM_MODEL.isDelegating.name})
    const addNewConversation = useNewConversation()
    const formValues = getValues()
    const watcher = watch()

    const openTicket = useOpenTicket({
        onSuccess: () => toast.success(t('success:ticket_sent')),
        onError: () => toast.error(t('errors:default'))
    })

    const onChangeStep = () => {
        if (step == 'info' && isDelegating) {
            setStep('delegation')
        } else {
            setStep('content')
        }
    }

    const onGoBack = () => {
        if (step == 'info') {
            onClose()
        }
        if (step == 'content' && isDelegating) {
            setStep('delegation')
        } else {
            setStep('info')
        }
    }

    const onSubmit: SubmitHandler<OpenTicketFormSchema> = async data => {
        try {
            const newConversationId =
                conversationId ?? (await addNewConversation.mutateAsync({type: searchParams.user})).data.id
            const ticket = adaptTicketToPostMutation(newConversationId, data)
            await openTicket.mutateAsync(OpenTicketPayload.parse(ticket))
            // Reset store
            setSearchParams({ticket: undefined})
            resetChatbot()
            showOpenTicketModal(false)
        } catch (error) {
            console.error(error)
        }
    }

    const onClose = () => {
        showOpenTicketModal(false)
        setSearchParams({ticket: undefined})
    }

    const isValidCurrentStep = useMemo(() => {
        return checkValidityStep(step, formValues, errors)
    }, [watcher])

    return (
        <StyledOpenTicketModal width={600} onOverlayClick={() => step == 'info' && onClose()}>
            <StyledOpenTicketModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={onClose}>
                    <XCloseIcon />
                </Button>
                <h3>{t('ticket_modal:title')}</h3>
            </StyledOpenTicketModalHeader>

            <Divider direction="horizontal" />

            <StyledOpenTicketModalBody gap={6} direction="column">
                <Flexbox direction="column" gap={2}>
                    <StyledFormStepTitle>{t(`ticket_modal:form:step_${step}_title`)}</StyledFormStepTitle>
                    {step == 'delegation' && (
                        <StyledFormStepSubtitle>
                            {t('ticket_modal:form:step_delegation_subtitle')}
                        </StyledFormStepSubtitle>
                    )}
                </Flexbox>

                <FormProvider {...methods}>
                    <StyledOpenTicketModalForm id="open-ticket-form" onSubmit={handleSubmit(onSubmit)}>
                        {step == 'info' && <InfoFormStep isPending={openTicket.isPending} />}
                        {step == 'delegation' && <DelegationFormStep isPending={openTicket.isPending} />}
                        {step == 'content' && <ContentFormStep isPending={openTicket.isPending} />}
                    </StyledOpenTicketModalForm>
                </FormProvider>
            </StyledOpenTicketModalBody>

            <Divider direction="horizontal" />

            <StyledOpenTicketModalFooter justify="space-between">
                <Button variant="ghost" onClick={onGoBack}>
                    {step == 'info' ? t('ticket_modal:close') : t('commons:back')}
                </Button>

                {step == 'content' ? (
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValidCurrentStep || openTicket.isPending}
                        onClick={() => handleSubmit(onSubmit)()}
                    >
                        {t('commons:send')}
                    </Button>
                ) : (
                    <Button variant="primary" disabled={!isValidCurrentStep} onClick={onChangeStep}>
                        {t('ticket_modal:continue')}
                    </Button>
                )}
            </StyledOpenTicketModalFooter>
        </StyledOpenTicketModal>
    )
}
