import {FC, useMemo, useState} from 'react'
import {MessageData} from '@/features/chatbot/types'
import {Button} from '@/components/ui/button/Button'
import {Trans, useTranslation} from 'react-i18next'
import {FaceFrownIcon, FaceSmileIcon, Mail01Icon, PhoneCall01Icon} from '@/components/ui/icon/Icon'
import {Divider} from '@/components/ui/divider/Divider'
import {
    StyledDividerOr,
    StyledDefaultCtaMessage,
    StyledMessageCtaPhone
} from '@/features/chatbot/components/default-cta-message/style'
import {useTheme} from 'styled-components'
import {useChatbotStore} from '@/features/chatbot/store/store'
import {useSendFeedbackConversation} from '@/features/chatbot/services/useSendFeedbackConversation'
import toast from 'react-hot-toast'

interface DefaultCtaMessageProps {
    defaultMessage: MessageData['default']
}

export const DefaultCtaMessage: FC<DefaultCtaMessageProps> = ({defaultMessage}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const showOpenTicketModal = useChatbotStore(state => state.showOpenTicketModal)
    const conversationId = useChatbotStore(state => state.conversationId)
    const conversation = useChatbotStore(state => state.conversation)
    const updateConversationSatisfaction = useChatbotStore(state => state.updateConversationSatisfaction)
    const addDefaultMessage = useChatbotStore(state => state.addDefaultMessage)
    const hideDefaultMessage = useChatbotStore(state => state.hideDefaultMessage)
    // This is a warkaround to allow the update of thumbs. Remove this after backend updates
    const [isSatisfied, setIsSatisfied] = useState<boolean | null>(null)

    const lastMessage = useMemo(() => conversation.filter(message => !message.default).at(-1), [conversation])

    const sendFeedbackConversation = useSendFeedbackConversation({
        onSuccess: () => {
            updateConversationSatisfaction(isSatisfied)
            toast.success(t('success:feedback_sent'))
            // To do: could remove these conditions if BE sends us an event to the socket.
            if (!isSatisfied && !lastMessage?.isPhoneAvailable) {
                addDefaultMessage('send_ticket')
            } else if (!isSatisfied && lastMessage?.isPhoneAvailable) {
                addDefaultMessage('send_ticket_or_call')
            } else {
                hideDefaultMessage()
            }
        },
        onError: () => {
            toast.error(t('errors:default'))
        }
    })

    const onSendFeedback = (isSatisfied: boolean) => {
        if (!conversationId) return

        setIsSatisfied(isSatisfied)

        sendFeedbackConversation.mutate({
            urlParams: {
                conversationId
            },
            payload: {
                isSatisfied
            }
        })
    }

    if (defaultMessage == 'show_satisfaction')
        return (
            <StyledDefaultCtaMessage gap={2}>
                <Button variant="secondary" onClick={() => onSendFeedback(true)}>
                    <FaceSmileIcon color={palette.success[600]} />
                    {t('default_message:cta:satisfied')}
                </Button>
                <Button variant="secondary" onClick={() => onSendFeedback(false)}>
                    <FaceFrownIcon color={palette.warning[600]} />
                    {t('default_message:cta:not_satisfied')}
                </Button>
            </StyledDefaultCtaMessage>
        )

    if (defaultMessage?.includes('send_ticket'))
        return (
            <StyledDefaultCtaMessage direction="column">
                <Button fullWidth onClick={() => showOpenTicketModal(true)}>
                    <Mail01Icon />
                    {t('default_message:cta:send_ticket')}
                </Button>

                {defaultMessage == 'send_ticket_or_call' && (
                    <>
                        <StyledDividerOr align="center" gap={2.5}>
                            <Divider direction="horizontal" />
                            <p>{t('commons:or')}</p>
                            <Divider direction="horizontal" />
                        </StyledDividerOr>
                        <StyledMessageCtaPhone align="center" justify="center" direction="row" gap={4}>
                            <PhoneCall01Icon size={20} />
                            <div>
                                <Trans
                                    i18nKey={t('default_message:cta:call_number')}
                                    components={{
                                        a_1: <a href="tel:+390658703011" />,
                                        a_2: <a href="tel:+390658703019" />
                                    }}
                                    values={{number_1: '06 58703011', number_2: '06 58703019'}}
                                />
                            </div>
                        </StyledMessageCtaPhone>
                    </>
                )}
            </StyledDefaultCtaMessage>
        )

    return <div />
}
