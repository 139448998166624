import {MUTATION_KEYS} from '@/queryClient'
import {useMutation} from '@tanstack/react-query'
import {AddConversationOptions, addConversation} from '@/features/chatbot/services/chatbot.http'
import toast from 'react-hot-toast'
import {useTranslation} from 'react-i18next'

export const useNewConversation = () => {
    const {t} = useTranslation()

    return useMutation({
        mutationKey: [MUTATION_KEYS.NEW_CONVERSATION],
        mutationFn: (options: AddConversationOptions) => addConversation(options),
        onError: () => {
            toast.error(t('errors:default'))
        }
    })
}
