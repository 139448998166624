import {FC, HTMLAttributes, ReactNode} from 'react'
import {MessageData} from '../../types'
import AiAvatar from '@assets/images/logo_mark.svg'
import {User01Icon} from '@/components/ui/icon/Icon'
import {useTranslation} from 'react-i18next'
import {
    StyledBlinkingCursor,
    StyledMessageAuthor,
    StyledAvatarMessage,
    StyledMessageContent,
    StyledMessageAIContainer,
    StyledMessageUserContent,
    StyledUserMessageSafeContent
} from '@/features/chatbot/components/message/style'
import {MarkdownText} from '@/components/commons/markdown-text/MarkdownText'

interface MessageProps extends HTMLAttributes<HTMLDivElement> {
    message: MessageData
    isLoading?: boolean
    endSlot?: ReactNode
    isLastMessage?: boolean
}

export const Message: FC<MessageProps> = ({message, isLoading, endSlot, isLastMessage, ...props}) => {
    const {t} = useTranslation()

    if (message.isAi) {
        return (
            <StyledMessageAIContainer gap={3} $isActive={message.isSatisfied != null || !!isLastMessage}>
                <StyledAvatarMessage align="center" justify="center">
                    <img src={AiAvatar} alt={t('commons:title')} height={32} />
                </StyledAvatarMessage>

                <StyledMessageContent {...props}>
                    <StyledMessageAuthor>{t('commons:title')}</StyledMessageAuthor>
                    {isLoading && !message.default && !message.text ? (
                        <StyledBlinkingCursor />
                    ) : (
                        <div>
                            {message.default ? (
                                t(`default_message:${message.default}`)
                            ) : (
                                <MarkdownText content={message.text} />
                            )}
                        </div>
                    )}

                    {endSlot}
                </StyledMessageContent>
            </StyledMessageAIContainer>
        )
    }

    return (
        <StyledMessageUserContent gap={3}>
            <StyledAvatarMessage align="center" justify="center">
                <User01Icon size={20} />
            </StyledAvatarMessage>

            <StyledUserMessageSafeContent {...props}>
                <StyledMessageAuthor>{t('commons:you')}</StyledMessageAuthor>
                <StyledMessageContent>{message.text}</StyledMessageContent>

                {endSlot}
            </StyledUserMessageSafeContent>
        </StyledMessageUserContent>
    )
}
