import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledOpenTicketModal = styled(Modal)(
    ({theme: {mediaQueries}}) => css`
        display: flex;
        flex-direction: column;

        ${mediaQueries.m} {
            max-width: 100%;
            bottom: 0;
            left: 0;
            top: unset;
            height: 720px;
            max-height: 100%;
            transform: unset;
            width: 100%;
            max-width: unset;
        }
    `
)

export const StyledOpenTicketModalHeader = styled.div(
    ({theme: {spacing, palette, typography}}) => css`
        display: grid;
        text-align: center;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        padding: ${spacing * 2.5}px ${spacing * 2}px;
        font-weight: 700;
        color: ${palette.neutral[900]};

        & h3 {
            ${typography.textSm};
        }
    `
)

export const StyledOpenTicketModalFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        ${spacing * 6}px;
    `
)

export const StyledOpenTicketModalBody = styled(Flexbox)(
    ({theme: {spacing, mediaQueries}}) => css`
        padding: ${spacing * 6}px;
        min-height: 440px;
        flex: 1;

        ${mediaQueries.m} {
            min-height: unset;
            overflow-y: scroll;
        }
    `
)

export const StyledOpenTicketModalForm = styled.form`
    width: 100%;
`

export const StyledFormStepTitle = styled.h3(
    ({theme: {palette, typography}}) => css`
        ${typography.textLg};
        color: ${palette.neutral[900]};
        font-weight: 500;
    `
)

export const StyledFormStepSubtitle = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[900]};
        font-weight: 400;
    `
)

export const StyledFormStepRow = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        width: 100%;
        ${mediaQueries.m} {
            flex-direction: column;
        }
    `
)

export const PhoneInputWrapper = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;

        .react-tel-input > input {
            ${typography.textMd}
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing}px;
            border: 1px solid ${palette.neutral['300']};
            width: 100%;
            transition: ease-in-out 200ms;
            cursor: pointer;
            height: 44px;
            padding: 0 ${spacing * 9}px 0 ${spacing * 12}px;
            &:not(:disabled):hover {
                border: 1px solid ${palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['25']}`};
            }
            &:focus {
                outline: none;
                border-color: ${palette.neutral['300']};
                box-shadow: unset;
            }
            &::placeholder {
                color: ${palette.neutral['400']};
            }
            &:disabled {
                cursor: not-allowed;
            }
        }
    `}
`

export const StyledOpenTicketModalRadioGrid = styled.div(
    ({theme: {spacing, typography, mediaQueries}}) => css`
        ${typography.textSm};
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: ${spacing * 2.5}px;

        ${mediaQueries.m} {
            grid-template-columns: 1fr;
        }
    `
)

export const InputFilePlaceholder = styled(Flexbox)(
    ({theme: {typography, spacing, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[900]};
        margin-left: ${spacing * 3}px;
    `
)

export const InputFileValue = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textSm};
        width: 100%;
        color: ${palette.neutral[900]};
        margin-left: ${spacing * 3}px;
        font-weight: 500;
    `
)

export const InputFileHighlightedPlaceholder = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.primary[600]};
        text-decoration: underline;
        font-weight: 500;
    `
)

export const StyledRemoveFileButton = styled(Button)(
    ({theme: {palette}}) => css`
        color: ${palette.danger[700]};
        flex-shrink: 0;
    `
)
