import {z} from 'zod'

export const SocketEvent = z.enum(['init', 'message', 'error'])
export type SocketEvent = z.infer<typeof SocketEvent>

export const ChatbotUserSearchParam = z.object({
    user: z.enum(['citizen', 'operator']).default('citizen'),
    ticket: z.enum(['true', 'false']).default('false')
})
export type ChatbotUserSearchParam = z.infer<typeof ChatbotUserSearchParam>

const MessageData = z.object({
    text: z.string(),
    isAi: z.boolean(),
    default: z.enum(['send_ticket', 'send_ticket_or_call', 'show_satisfaction']).nullish(),
    messageId: z.number().optional(),
    isSatisfied: z.boolean().optional(),
    isPhoneAvailable: z.boolean().optional(), // workaround to avoid the BE limitations that doesn't emit an event after the user sends a conversation feedback
    callback: z
        .object({
            label: z.string(),
            onClick: z.function()
        })
        .optional()
})
export type MessageData = z.infer<typeof MessageData>

export const ChatbotInfo = z.object({
    id: z.number(),
    sessionId: z.string()
})
export type ChatbotInfo = z.infer<typeof ChatbotInfo>

export const SocketError = z.object({
    code: z.number(),
    message: z.string(),
    status: z.enum(['error'])
})
export type SocketError = z.infer<typeof SocketError>

const FormTicketStep = z.enum(['info', 'delegation', 'content'])
export type FormTicketStep = z.infer<typeof FormTicketStep>
