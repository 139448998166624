import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledAutoscalingTextareaContainer = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px ${spacing * 3}px;
        border: 1px solid ${palette.neutral[300]};
        border-radius: 8px;
        width: 100%;
    `
)

export const StyledAutoscalingTextarea = styled.textarea<{$height: number | 'auto'}>(
    ({theme: {palette, typography, spacing}, $height}) => css`
        ${typography.textMd};
        resize: none;
        height: ${$height == 'auto' ? 'auto' : $height + 'px'};
        width: 100%;
        max-height: 200px;
        border: none;
        outline: none;
        color: ${palette.neutral['900']};
        padding-right: ${spacing * 2}px;
        white-space: pre-wrap

        &:focus {
            outline: none;
        }
        &::placeholder {
            color: ${palette.neutral['400']};
        }
        &:disabled {
            cursor: not-allowed;
        }
    `
)

export const StyledButton = styled(Button)(
    ({theme: {palette}}) => css`
        flex-shrink: 0;
        align-self: end;

        &:disabled {
            background: ${palette.neutral['200']};
            border: 1px solid ${palette.neutral['200']};
            color: ${palette.neutral['400']};
            cursor: not-allowed;
        }
    `
)
