import styled, {css, DefaultTheme} from 'styled-components'
import {InputWrapper} from '@/components/ui/input-wrapper/InputWrapper'
import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import {CSSProperties} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledInputFile = styled(Flexbox)<{$width: CSSProperties['width']}>(
    ({$width}) => css`
        width: ${$width};
    `
)

export const StyledInputWrapper = styled(InputWrapper)<{size: 'md'}>(
    ({size, theme}) => css`
        ${InputSizeStyles(theme)[size]};

        .file-name {
            flex: 1;
            font-size: ${theme.typography.textSm};
            font-weight: 500;
            color: ${theme.palette.neutral['700']};
        }
    `
)

export const StyledLabelContainer = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        width: 100%;
        color: ${palette.neutral[600]};

        & a {
            cursor: pointer;
            color: ${palette.primary[600]};
            text-decoration: underline;
        }
    `
)

export const StyledDropzone = styled(Dropzone)<{width: CSSProperties['width']}>(
    ({width}) => css`
        width: ${width};
    `
)

const InputSizeStyles = ({spacing}: DefaultTheme) => ({
    md: css`
        height: 70px;
        padding: 0 ${spacing * 4}px 0 ${spacing * 4}px;
    `
})
