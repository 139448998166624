import {FC} from 'react'
import {Radio} from '@/components/commons/radio/Radio'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {OpenTicketFormSchema} from '@/features/chatbot/components/open-ticket-modal/TicketFormModel'
import {StyledOpenTicketModalRadioGrid} from '@/features/chatbot/components/open-ticket-modal/style'
import {useFormContext, useWatch} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {MAX_CONTENT_LENGTH, OPEN_TICKET_FORM_MODEL, TICKET_TYPES_MODEL} from '@/features/chatbot/utils'
import {TextArea} from '@/components/commons/textarea/TextArea'

export const ContentFormStep: FC<{isPending: boolean}> = ({isPending}) => {
    const {t} = useTranslation()
    const {
        register,
        control,
        formState: {errors, touchedFields}
    } = useFormContext<OpenTicketFormSchema>()
    const content = useWatch({control, name: OPEN_TICKET_FORM_MODEL.content.name})

    return (
        <Flexbox gap={4} direction="column">
            <StyledOpenTicketModalRadioGrid>
                {TICKET_TYPES_MODEL.map(type => (
                    <Radio
                        key={type.value}
                        label={t(type.label)}
                        value={type.value}
                        disabled={isPending}
                        {...register(t(OPEN_TICKET_FORM_MODEL.type.name))}
                    />
                ))}
            </StyledOpenTicketModalRadioGrid>

            <TextArea
                label={t(OPEN_TICKET_FORM_MODEL.content.label)}
                rows={5}
                cols={33}
                touched={touchedFields.content}
                errorMessage={t(errors.content?.message || '')}
                placeholder={t('ticket_modal:form:content_placeholder')}
                helpText={`${content ? content.length : 0}/${MAX_CONTENT_LENGTH}`}
                disabled={isPending}
                {...register(OPEN_TICKET_FORM_MODEL.content.name)}
            />
        </Flexbox>
    )
}
