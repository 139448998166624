import {FC} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    InputFileHighlightedPlaceholder,
    InputFilePlaceholder,
    InputFileValue,
    StyledFormStepRow,
    StyledRemoveFileButton
} from '@/features/chatbot/components/open-ticket-modal/style'
import {InputText} from '@/components/commons/input-text/InputText'
import {ACCEPTED_FILE_TYPES, MAX_UPLOAD_SIZE, OPEN_TICKET_FORM_MODEL} from '@/features/chatbot/utils'
import {Trans, useTranslation} from 'react-i18next'
import {Controller, useFormContext, useWatch} from 'react-hook-form'
import {OpenTicketFormSchema} from '@/features/chatbot/components/open-ticket-modal/TicketFormModel'
import {InputFile} from '@/components/commons/input-file/InputFile'
import {FileCheck01Icon, UploadCloud01Icon} from '@/components/ui/icon/Icon'
import {useTheme} from 'styled-components'

export const DelegationFormStep: FC<{isPending: boolean}> = ({isPending}) => {
    const {t} = useTranslation()
    const {
        register,
        control,
        formState: {errors, touchedFields}
    } = useFormContext<OpenTicketFormSchema>()
    const {palette} = useTheme()
    const signedDelegationFile = useWatch({control, name: OPEN_TICKET_FORM_MODEL.signedDelegationFile.name})
    const identityDocumentFile = useWatch({control, name: OPEN_TICKET_FORM_MODEL.identityDocumentFile.name})

    return (
        <Flexbox gap={4} direction="column">
            <StyledFormStepRow gap={4}>
                <InputText
                    label={`${t(OPEN_TICKET_FORM_MODEL.patientFirstName.label)}*`}
                    placeholder={t(OPEN_TICKET_FORM_MODEL.patientFirstName.label)}
                    type={'text'}
                    touched={touchedFields.patientFirstName}
                    errorMessage={t(errors.patientFirstName?.message || '')}
                    disabled={isPending}
                    maxLength={64}
                    {...register(OPEN_TICKET_FORM_MODEL.patientFirstName.name)}
                />
                <InputText
                    label={`${t(OPEN_TICKET_FORM_MODEL.patientLastName.label)}*`}
                    placeholder={t(OPEN_TICKET_FORM_MODEL.patientLastName.label)}
                    type={'text'}
                    touched={touchedFields.patientLastName}
                    errorMessage={t(errors.patientLastName?.message || '')}
                    disabled={isPending}
                    maxLength={64}
                    {...register(OPEN_TICKET_FORM_MODEL.patientLastName.name)}
                />
            </StyledFormStepRow>

            <Controller
                render={({field: {onChange, ref}}) => (
                    <InputFile
                        ref={ref}
                        acceptedFiles={ACCEPTED_FILE_TYPES}
                        maxSizeInMB={MAX_UPLOAD_SIZE}
                        label={t(OPEN_TICKET_FORM_MODEL.signedDelegationFile.label)}
                        disabled={isPending}
                        typeIcon={
                            signedDelegationFile ? (
                                <FileCheck01Icon color={palette.success[600]} />
                            ) : (
                                <UploadCloud01Icon color={palette.neutral[600]} />
                            )
                        }
                        onAcceptFiles={file => onChange(file)}
                        touched={touchedFields.signedDelegationFile}
                        noClick={!!signedDelegationFile}
                        labelEndSlot={
                            <div>
                                <Trans
                                    i18nKey={t('ticket_modal:form:delegation_link')}
                                    components={{
                                        a: (
                                            <a
                                                href="https://www.scamilloforlanini.rm.it/documents/d/guest/modulo_delega"
                                                target="_blank"
                                            />
                                        )
                                    }}
                                />
                            </div>
                        }
                        centerSlot={
                            signedDelegationFile ? (
                                <InputFileValue justify="space-between" align="center">
                                    {signedDelegationFile.name}
                                </InputFileValue>
                            ) : (
                                <InputFilePlaceholder direction="column">
                                    <div>
                                        <Trans
                                            i18nKey={t('ticket_modal:form:input_file_placeholder')}
                                            components={{span: <InputFileHighlightedPlaceholder />}}
                                        />
                                    </div>
                                    {t('ticket_modal:form:input_file_available')}
                                </InputFilePlaceholder>
                            )
                        }
                        endSlot={
                            signedDelegationFile && (
                                <StyledRemoveFileButton variant="ghost" onClick={() => onChange(null)}>
                                    {t('commons:remove')}
                                </StyledRemoveFileButton>
                            )
                        }
                    />
                )}
                control={control}
                name={OPEN_TICKET_FORM_MODEL.signedDelegationFile.name}
            />

            <Controller
                render={({field: {onChange, ref}}) => (
                    <InputFile
                        ref={ref}
                        acceptedFiles={ACCEPTED_FILE_TYPES}
                        maxSizeInMB={MAX_UPLOAD_SIZE}
                        label={t(OPEN_TICKET_FORM_MODEL.identityDocumentFile.label)}
                        disabled={isPending}
                        typeIcon={
                            identityDocumentFile ? (
                                <FileCheck01Icon color={palette.success[600]} />
                            ) : (
                                <UploadCloud01Icon color={palette.neutral[600]} />
                            )
                        }
                        onAcceptFiles={file => onChange(file)}
                        touched={touchedFields.identityDocumentFile}
                        noClick={!!identityDocumentFile}
                        centerSlot={
                            identityDocumentFile ? (
                                <InputFileValue justify="space-between" align="center">
                                    {identityDocumentFile.name}
                                </InputFileValue>
                            ) : (
                                <InputFilePlaceholder direction="column">
                                    <div>
                                        <Trans
                                            i18nKey={t('ticket_modal:form:input_file_placeholder')}
                                            components={{span: <InputFileHighlightedPlaceholder />}}
                                        />
                                    </div>
                                    {t('ticket_modal:form:input_file_available')}
                                </InputFilePlaceholder>
                            )
                        }
                        endSlot={
                            identityDocumentFile && (
                                <StyledRemoveFileButton variant="ghost" onClick={() => onChange(null)}>
                                    {t('commons:remove')}
                                </StyledRemoveFileButton>
                            )
                        }
                    />
                )}
                control={control}
                name={OPEN_TICKET_FORM_MODEL.identityDocumentFile.name}
            />
        </Flexbox>
    )
}
