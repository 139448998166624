import {MUTATION_KEYS} from '@/queryClient'
import {useMutation} from '@tanstack/react-query'
import {OpenTicketPayload, OpenTicketResponse, openTicket} from '@/features/chatbot/services/chatbot.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types'
import {captureException} from '@sentry/react'
import {parseAxiosPromise} from '@/axiosInstance'

type UseOpenTicket = {
    onSuccess?: (data: OpenTicketResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useOpenTicket = (options?: UseOpenTicket) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.OPEN_TICKET],
        mutationFn: (params: OpenTicketPayload) =>
            parseAxiosPromise({
                axiosPromise: openTicket(params),
                onZodError: captureException,
                responseShape: OpenTicketResponse
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: data => options?.onSuccess?.(data)
    })
}
