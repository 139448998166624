import styled, {css} from 'styled-components'
import {Container} from '@components/ui/container/Container.tsx'
import NotFoundBg from '@assets/images/dot-pattern-bg.png'

export const StyledContainer = styled(Container)`
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    background: ${`url("${NotFoundBg}");`};
    box-shadow: -1px -200px 2000px 399px rgba(255, 255, 255, 0.9) inset;
`

export const StyledNotFoundWrapper = styled.div`
    ${({theme: {spacing, typography, palette}}) => css`
        position: relative;
        display: grid;
        justify-items: center;
        align-items: center;
        gap: ${spacing * 6}px;
        grid-template-columns: 1fr;
        max-width: 1024px;

        & h1 {
            ${typography.displayXl};
            font-weight: 600;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textXl};
            color: ${palette.neutral['600']};
            margin-bottom: ${spacing * 4}px;
        }
    `}
`
