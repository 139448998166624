import {z} from 'zod'
import {ACCEPTED_FILE_TYPES, MAX_CONTENT_LENGTH, MAX_UPLOAD_SIZE, sizeInMB} from '../../utils'

export const OpenTicketFormSchema = z.object({
    senderFirstName: z.string().min(1, {message: 'errors:required'}),
    senderLastName: z.string().min(1, {message: 'errors:required'}),
    patientFirstName: z.string().min(1, {message: 'errors:required'}).optional(),
    patientLastName: z.string().min(1, {message: 'errors:required'}).optional(),
    email: z.string().email({message: 'errors:email'}),
    phoneNumber: z
        .string()
        .min(3, {message: 'ticket_modal:errors:phoneNumber'})
        .max(15, {message: 'ticket_modal:errors:phoneNumber'})
        .refine(value => value.match('[0-9]+'), {message: 'ticket_modal:errors:phoneNumber'}),
    isDelegating: z.boolean(),
    content: z.string().trim().max(MAX_CONTENT_LENGTH, {message: 'ticket_modal:errors:max_content'}),
    type: z.enum(['praise', 'complaint', 'help', 'refund', 'suggestion', 'info']).default('info'),
    identityDocumentFile: z
        .instanceof(File)
        .optional()
        .refine(file => {
            return !file || sizeInMB(file.size) <= MAX_UPLOAD_SIZE
        }, `The maximum image size is ${MAX_UPLOAD_SIZE}MB`)
        .refine(file => {
            return file?.type && Object.keys(ACCEPTED_FILE_TYPES).includes(file?.type)
        }, 'File type is not supported')
        .nullable(),
    signedDelegationFile: z
        .instanceof(File)
        .optional()
        .refine(file => {
            return !file || sizeInMB(file.size) <= MAX_UPLOAD_SIZE
        }, `The maximum image size is ${MAX_UPLOAD_SIZE}MB`)
        .refine(file => {
            return file?.type && Object.keys(ACCEPTED_FILE_TYPES).includes(file?.type)
        }, 'File type is not supported')
        .nullable()
})
export type OpenTicketFormSchema = z.infer<typeof OpenTicketFormSchema>
